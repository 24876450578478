import SharePointList from './Components/SharePointList';
import { DisplayContainer, StepperRagWrapper } from './Style';
import { useNavigate, useParams } from 'react-router-dom';
import { useRagConfig } from '../../../../../Contexts/RagConfigContext';
import { generateExcel, getRagStatus, indexRagConfiguration } from '../../../../../Tools/Api';
import Button from '../../../../../Components/Button/Button';
import { useEffect, useState } from 'react';
import { useUserContext } from '../../../../../Contexts/UserContext';
import { useTranslation } from 'react-i18next';
import WebSitesList from './Components/WebSitesList';
import { toastError } from '../../../../../Tools/Toast';
import FileList from './Components/FileList';

export interface StatusProps {
  statusLabelMap: any;
  getStatusSeverity: (
    indexationStatus: API_BACKEND.Schemas.RAGStatus | string | undefined
  ) => 'success' | 'info' | 'warning' | 'danger' | null | undefined;
  getStatusOfSource: (
    source: API_BACKEND.Schemas.AbstractRAGConfigurationProcessSourceStatusSourceDTO | undefined
  ) => API_BACKEND.Schemas.RAGStatus | string;
  getSourceStatusBySourceId: (
    sourcesStatus,
    sourceId
  ) => API_BACKEND.Schemas.AbstractRAGConfigurationProcessSourceStatusSourceDTO | undefined;
  isStatusLoading: boolean;
}

const Display = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [indexationGlobalStatus, setIndexationGlobalStatus] = useState<API_BACKEND.Schemas.RAGStatus | undefined>();
  const [lastActionStatus, setLastActionStatus] = useState<
    API_BACKEND.Schemas.RAGConfigurationProcessDTO | undefined
  >();
  const [generateExcelGlobalStatus, setGenerateExcelGlobalStatus] = useState<
    API_BACKEND.Schemas.RAGStatus | undefined
  >();
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(true);
  const { setSelectedRagConfiguration, selectedRagConfiguration } = useRagConfig();
  const { casClient } = useUserContext();
  const { t } = useTranslation();
  const token = casClient.getToken();

  const handlePrevious = () => {
    navigate(-1);
    setSelectedRagConfiguration && setSelectedRagConfiguration(null);
  };

  const isSourcesEmpty =
    selectedRagConfiguration?.content?.sources?.files?.length === 0 &&
    selectedRagConfiguration?.content?.sources?.sharepoints?.length === 0 &&
    selectedRagConfiguration?.content?.sources?.websites?.length === 0;

  const isIndexationInProgress = indexationGlobalStatus === 'IN_PROGRESS';
  const isGenerateExcelInProgress = generateExcelGlobalStatus === 'IN_PROGRESS';

  const shouldDisableActions = isSourcesEmpty || isIndexationInProgress || isGenerateExcelInProgress;

  const statusLabelMap = {
    '': t('config_rag.general.status.sources.waiting'),
    IN_PROGRESS: t('config_rag.general.status.sources.inProgress'),
    COMPLETED: t('config_rag.general.status.sources.completed'),
    COMPLETED_WITH_ERRORS: t('config_rag.general.status.sources.completedWithErrors'),
    FAILED: t('config_rag.general.status.sources.failed'),
  };

  const getStatusSeverity = (
    status: API_BACKEND.Schemas.RAGStatus | string | undefined
  ): 'success' | 'info' | 'warning' | 'danger' | null | undefined => {
    switch (status) {
      case 'IN_PROGRESS':
        return 'info';
      case 'COMPLETED':
        return 'success';
      case 'COMPLETED_WITH_ERRORS':
        return 'danger';
      case 'FAILED':
        return 'danger';
      default:
        return 'warning';
    }
  };

  const getSourceStatusBySourceId = (
    sourcesStatus: API_BACKEND.Schemas.AbstractRAGConfigurationProcessSourceStatusSourceDTO[],
    sourceId: string
  ): API_BACKEND.Schemas.AbstractRAGConfigurationProcessSourceStatusSourceDTO | undefined => {
    return sourcesStatus?.filter((s) => s.sourceId === sourceId)[0];
  };

  const getStatusOfSource = (
    source: API_BACKEND.Schemas.AbstractRAGConfigurationProcessSourceStatusSourceDTO | undefined
  ): API_BACKEND.Schemas.RAGStatus | string => {
    if (source) {
      if (isGlobalStatusInProgress()) {
        return 'IN_PROGRESS';
      } else if (isGlobalStatusFailed()) {
        return 'FAILED';
      } else if (source.isExtractionSuccessful) {
        return 'COMPLETED';
      } else {
        return 'COMPLETED_WITH_ERRORS';
      }
    }

    return '';
  };

  const getStatusToSave = (
    statusToReplace: API_BACKEND.Schemas.RAGConfigurationProcessDTO | undefined,
    ragStatus: API_BACKEND.Schemas.RAGConfigurationProcessDTO | undefined
  ) => {
    if (!statusToReplace) {
      return ragStatus;
    } else if (ragStatus?.updatedAt && statusToReplace.updatedAt) {
      const newRagStatusDate = new Date(ragStatus.updatedAt);
      const statusToReplaceDate = new Date(statusToReplace.updatedAt);

      if (newRagStatusDate > statusToReplaceDate) {
        return ragStatus;
      }
    }
    return statusToReplace;
  };

  const handleRagStatusSuccess = (response: API_BACKEND.Schemas.RAGConfigurationProcessDTO) => {
    const statusToSave = getStatusToSave(lastActionStatus, response);
    if (response?.source === 'INDEXATION_UPDATE') {
      setIndexationGlobalStatus(response.status);
    } else if (response?.source === 'KB_CREATION') {
      setGenerateExcelGlobalStatus(response.status);
    }
    setLastActionStatus(statusToSave);
  };

  const checkStatus = (source: API_BACKEND.Schemas.RAGLogSource) => {
    if (id) {
      getRagStatus(id, source, token).then(handleRagStatusSuccess);
    }
  };

  const getCheckStatusPromise = (source: API_BACKEND.Schemas.RAGLogSource) => {
    if (id) {
      return getRagStatus(id, source, token);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setIsStatusLoading(true);
    const checkStatusRequests: Promise<any>[] = [];
    if (!indexationGlobalStatus || indexationGlobalStatus === 'IN_PROGRESS') {
      checkStatusRequests.push(getCheckStatusPromise('INDEXATION_UPDATE'));
    }
    if (!generateExcelGlobalStatus || generateExcelGlobalStatus === 'IN_PROGRESS') {
      checkStatusRequests.push(getCheckStatusPromise('KB_CREATION'));
    }

    Promise.allSettled(checkStatusRequests).then((results) => {
      let latestStatus: API_BACKEND.Schemas.RAGConfigurationProcessDTO | undefined = undefined;
      for (const result of results) {
        if (result && result.status === 'fulfilled') {
          latestStatus = getStatusToSave(latestStatus, result.value);
        }
      }

      if (latestStatus) {
        handleRagStatusSuccess(latestStatus);
      }

      setIsStatusLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isIndexationInProgress) {
      {
        const intervalId = setInterval(() => {
          if (id) {
            checkStatus('INDEXATION_UPDATE');
          }
        }, 5000);
        return () => clearInterval(intervalId);
      }
    }
    if (isGenerateExcelInProgress) {
      {
        const intervalId = setInterval(() => {
          if (id) {
            checkStatus('KB_CREATION');
          }
        }, 5000);
        return () => clearInterval(intervalId);
      }
    }
  }, [isIndexationInProgress, isGenerateExcelInProgress, id]);

  const handleIndexConfiguration = (id: string) => {
    indexRagConfiguration(id, token)
      .then(() => {
        setIndexationGlobalStatus('IN_PROGRESS');
        setTimeout(() => checkStatus('INDEXATION_UPDATE'), 500);
      })
      .catch(() => {
        toastError(t('config_rag.general.indexationError'));
      });
  };

  const handleGenerateExcel = (id: string) => {
    generateExcel(id, token)
      .then(() => {
        setGenerateExcelGlobalStatus('IN_PROGRESS');
        setTimeout(() => checkStatus('KB_CREATION'), 500);
      })
      .catch(() => {
        toastError(t('config_rag.general.generateExcelError'));
      });
  };

  const isGlobalStatusInProgress = (): boolean => {
    return lastActionStatus?.status === 'IN_PROGRESS';
  };

  const isGlobalStatusFailed = (): boolean => {
    return lastActionStatus?.status === 'FAILED';
  };

  return (
    <>
      <DisplayContainer>
        <h1>{selectedRagConfiguration?.name}</h1>
        <div className='files-wrapper'>
          <span>{t('config_rag.general.fileListTitle')}</span>
          <FileList
            sourcesStatus={lastActionStatus?.sourcesStatus?.files}
            getStatusSeverity={getStatusSeverity}
            statusLabelMap={statusLabelMap}
            getStatusOfSource={getStatusOfSource}
            getSourceStatusBySourceId={getSourceStatusBySourceId}
            isStatusLoading={isStatusLoading}
          />
        </div>
        <div className='sharepoint-wrapper'>
          <span>{t('config_rag.general.sharepointListTitle')}</span>
          <SharePointList
            sourcesStatus={lastActionStatus?.sourcesStatus?.sharepoints}
            getStatusSeverity={getStatusSeverity}
            statusLabelMap={statusLabelMap}
            getStatusOfSource={getStatusOfSource}
            getSourceStatusBySourceId={getSourceStatusBySourceId}
            isStatusLoading={isStatusLoading}
          />
        </div>
        <div className='website-wrapper'>
          <span>{t('config_rag.general.webSiteTitle')}</span>
          <WebSitesList
            sourcesStatus={lastActionStatus?.sourcesStatus?.websites}
            getStatusSeverity={getStatusSeverity}
            statusLabelMap={statusLabelMap}
            getStatusOfSource={getStatusOfSource}
            getSourceStatusBySourceId={getSourceStatusBySourceId}
            isStatusLoading={isStatusLoading}
          />
        </div>
      </DisplayContainer>
      <StepperRagWrapper>
        <div className='inner-content'>
          <Button
            label={t('config_rag.general.backToCollections')}
            onClick={handlePrevious}
            className='p-button-text p-button-secondary'
            icon='icon-caret-left'
          />
          <div className='stepper-rag-actions flex-grow-1'>
            <Button
              label={t('config_rag.general.knowledge')}
              onClick={() => id && handleGenerateExcel(id)}
              disabled={shouldDisableActions}
            />
            <Button
              label={t('config_rag.general.indexation')}
              onClick={() => id && handleIndexConfiguration(id)}
              disabled={shouldDisableActions}
            />
          </div>
        </div>
      </StepperRagWrapper>
    </>
  );
};

export default Display;
