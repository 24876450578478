import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import { getRagConfigurationsForBotUuid } from '../Tools/Api';
import { useUserContext } from './UserContext';

interface RagConfigProviderProps {
  ragConfigs: API_BACKEND.Schemas.RAGConfigurationDTO[];
  setRagConfigs?: (ragConfig: API_BACKEND.Schemas.RAGConfigurationDTO[]) => void;
  selectedRagConfiguration: API_BACKEND.Schemas.RAGConfigurationDTO | null;
  setSelectedRagConfiguration: (ragConfig: API_BACKEND.Schemas.RAGConfigurationDTO | null) => void | null;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export const useRagConfig = () => useContext<RagConfigProviderProps>(RagConfigContext);
export const RagConfigContext = createContext({} as RagConfigProviderProps);

const RagConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [ragConfigs, setRagConfigs] = useState<API_BACKEND.Schemas.RAGConfigurationDTO[]>([]);
  const [selectedRagConfiguration, setSelectedRagConfiguration] =
    useState<API_BACKEND.Schemas.RAGConfigurationDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { casClient } = useUserContext();
  const token = casClient.getToken();

  useEffect(() => {
    if (botUUID) {
      getRagConfigurationsForBotUuid(botUUID, token)
        .then((res: any) => {
          setRagConfigs(res);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [botUUID]);

  useEffect(() => {
    if (selectedRagConfiguration) {
      setRagConfigs(
        ragConfigs?.map((config) => (config.id === selectedRagConfiguration.id ? selectedRagConfiguration : config))
      );
    }
  }, [selectedRagConfiguration]);

  const props = {
    ragConfigs,
    setRagConfigs,
    selectedRagConfiguration,
    setSelectedRagConfiguration,
    setLoading,
    loading,
  };

  return <RagConfigContext.Provider children={children} value={props} />;
};

export default RagConfigProvider;
