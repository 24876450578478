import Button from '../../../Components/Button/Button';
import ModalCreateRag from './ModalCreateRag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import TypeList from '../../Configurations/Components/TypeList';
import { useRagConfig } from '../../../Contexts/RagConfigContext';
import { useUserContext } from '../../../Contexts/UserContext';
import { deleteRagConfiguration } from '../../../Tools/Api';
import Icon from '../../../Components/Icon/Icon';
import { CreateWrapper, IconTrigger } from '../Style';
import { APP_PATH } from '../../../Tools/Constants';
import ModalDelete from './ModalDelete';
import * as Constants from '../../../Tools/Constants';
import Loader from '../../../Components/Loader/Loader';
import useDocumentTitle from '../../../Hooks/useDocumentTitle';

const ConfigurationsRag = () => {
  sessionStorage.setItem(Constants.DyduStorage.NEXT, APP_PATH.CONFIG_RAG);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ragConfigs, setRagConfigs, setSelectedRagConfiguration, loading, setLoading } = useRagConfig();
  const { casClient } = useUserContext();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  useDocumentTitle(`${t('general.pages.rag')} - ${t('general.pages.ragConfigurations')}`);

  const token = casClient.getToken();

  useEffect(() => {
    if (ragConfigs.length > 0) {
      setLoading(false);
    }
  }, [ragConfigs]);

  const ragColumns = [
    {
      id: 'name',
      title: t('general.name'),
    },
    {
      id: 'author',
      title: t('general.author'),
    },
    {
      id: 'createdAt',
      title: t('general.createdAt'),
      body: (rowData: any) => {
        return new Date(rowData.createdAt).toLocaleDateString();
      },
    },
    {
      id: 'updatedAt',
      title: t('general.lastUpdate'),
      body: (rowData: any) => {
        const date = new Date(rowData.updatedAt);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${t('general.at')} ${hours}:${minutes}`;
      },
    },
    {
      id: 'actions',
      title: t('general.actions'),
      body: (rowData: API_BACKEND.Schemas.RAGConfigurationDTO) => {
        return (
          <IconTrigger
            onClick={(event) => {
              event.stopPropagation();
              setIdToDelete(rowData.id);
              setVisibleDelete(true);
            }}
          >
            <Icon color='red' size={40} icon='icon-trash' />
          </IconTrigger>
        );
      },
    },
  ];

  const handleOpenConfig = (id: string, type: string) => {
    const foundConfig = ragConfigs?.find((config: API_BACKEND.Schemas.RAGConfigurationDTO) => config.id === id);
    if (foundConfig && setSelectedRagConfiguration) {
      setSelectedRagConfiguration(foundConfig);
    }
    navigate(`${APP_PATH.CONFIGURATIONS}/${type}/${id}`);
  };

  const handleDeleteRag = () => {
    deleteRagConfiguration(idToDelete, token).then(() => {
      ragConfigs &&
        setRagConfigs &&
        setRagConfigs(
          ragConfigs?.filter((config: API_BACKEND.Schemas.RAGConfigurationDTO) => config.id !== idToDelete)
        );
    });
  };

  const listRagConfigurationWithType =
    ragConfigs && ragConfigs.length > 0
      ? ragConfigs?.map((config) => {
          return {
            ...config,
            ...{
              type: 'rag',
            },
          };
        })
      : [];

  const renderList = (configs: any, columns: any, title: string, subtitle: string, action: ReactNode) => (
    <TypeList
      data={configs}
      columns={columns}
      openConfig={handleOpenConfig}
      title={title}
      subtitle={subtitle}
      action={action}
    />
  );

  if (loading) {
    return <Loader />;
  }

  return ragConfigs?.length && ragConfigs?.length > 0 ? (
    <>
      {renderList(
        listRagConfigurationWithType,
        ragColumns,
        t('configurations.rag_configs.title'),
        '',
        <Button
          label={t('config_rag.create.buttonCreate')}
          onClick={() => setVisible(true)}
          icon='icon-add'
          disabled={false}
        />
      )}
      {<ModalCreateRag visible={visible} onHide={() => setVisible(false)} />}
      {
        <ModalDelete
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onHide={() => setVisibleDelete(false)}
          handleDelete={handleDeleteRag}
        />
      }
    </>
  ) : (
    <>
      <CreateWrapper>
        <div>
          <h1>{t('config_rag.create.firstCollection')}</h1>
          <h2>{t('config_rag.create.subtitle')}</h2>
          <h3>{t('config_rag.create.subtitle2')}</h3>
        </div>
        <div>
          <Button
            label={t('config_rag.create.buttonCreate')}
            onClick={() => setVisible(true)}
            icon='icon-add'
            disabled={false}
          />
          {<ModalCreateRag visible={visible} onHide={() => setVisible(false)} />}
        </div>
      </CreateWrapper>
    </>
  );
};

export default ConfigurationsRag;
