import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../Components/Input/Input';
import Button from '../../../../../../Components/Button/Button';
import { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { ModalUrls } from '../Style';
import { HeaderWrapper } from '../../../../../ConfigurationsRag/Style';
import { SelectButton } from 'primereact/selectbutton';
import { toastError } from '../../../../../../Tools/Toast';

interface ModalWebsitesProps {
  visible: boolean;
  onHide: () => void;
  handleAddWebsite: (data) => void;
  handleUpdateWebsite: (data) => void;
  currentWebSite: any;
  setCurrentWebSite: any;
}

const ModalWebsites = ({
  visible,
  onHide,
  handleAddWebsite,
  handleUpdateWebsite,
  currentWebSite,
  setCurrentWebSite,
}: ModalWebsitesProps) => {
  const { t } = useTranslation();
  const [specificUrl, setSpecificUrl] = useState<API_BACKEND.Schemas.RAGSourceWebsiteDTO>({ name: '', urls: [] });
  const [domainUrl, setDomainUrl] = useState<API_BACKEND.Schemas.RAGSourceWebsiteDTO>({ name: '', domainToCrawl: '' });
  const [sitemapUrl, setSitemapUrl] = useState<API_BACKEND.Schemas.RAGSourceWebsiteDTO>({ name: '', sitemapUrl: '' });
  const [newSpecificUrl, setNewSpecificUrl] = useState('');
  const [urlType, setUrlType] = useState(1);
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  enum UrlType {
    domainUrl = 1,
    SitemapUrl = 2,
    urls = 3,
  }

  useEffect(() => {
    if (isUpdate) {
      let type: UrlType;

      if (currentWebSite?.urls) {
        type = UrlType.urls;
      } else if (currentWebSite?.sitemapUrl) {
        type = UrlType.SitemapUrl;
      } else {
        type = UrlType.domainUrl;
      }

      setUrlType(type);
    }
  }, [currentWebSite]);

  const isUpdate = !!currentWebSite;
  const isValidUrl = (urlToCheck) => {
    try {
      return Boolean(new URL(urlToCheck));
    } catch (e) {
      return false;
    }
  };

  const listOfWebsiteType = [
    { name: t('config_rag.websites.urlTypeDomain'), value: 1 },
    { name: t('config_rag.websites.urlTypeSitemap'), value: 2 },
    { name: t('config_rag.websites.urlTypeSpecific'), value: 3 },
  ];

  const websiteTypes = isUpdate ? listOfWebsiteType.filter((type) => type.value === urlType) : listOfWebsiteType;

  const data = isUpdate ? currentWebSite : urlType === 1 ? domainUrl : urlType === 2 ? sitemapUrl : specificUrl;

  const handleAddUrl = () => {
    if (!isValidUrl(newSpecificUrl)) {
      setIsInvalidInput(true);
      toastError(t('config_rag.websites.invalidUrlError'));
      return;
    }
    isUpdate
      ? setCurrentWebSite({ ...currentWebSite, urls: [...(currentWebSite?.urls as []), newSpecificUrl] })
      : setSpecificUrl({ ...specificUrl, urls: [...(specificUrl.urls as []), newSpecificUrl] });
    setNewSpecificUrl('');
    setIsInvalidInput(false);
  };

  const cleanAllData = () => {
    setSpecificUrl({ name: '', urls: [] });
    setDomainUrl({ name: '', domainToCrawl: '' });
    setSitemapUrl({ name: '', sitemapUrl: '' });
    setNewSpecificUrl('');
  };

  const onHideModal = () => {
    cleanAllData();
    setIsInvalidInput(false);
    onHide();
  };

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('config_rag.websites.title')}</h1>
    </HeaderWrapper>
  );

  const renderSpecificUrl = () => {
    return (
      <>
        <div className='form-addurls'>
          <Input
            label={t('general.name')}
            className={'input-name'}
            placeholder={t('general.name')}
            value={currentWebSite?.name || specificUrl.name}
            invalid={
              isUpdate ? isInvalidInput && currentWebSite?.name === '' : isInvalidInput && specificUrl.name === ''
            }
            onChange={(e) => {
              isUpdate
                ? setCurrentWebSite({ ...currentWebSite, name: e.target.value })
                : setSpecificUrl({ ...specificUrl, name: e.target.value });
            }}
          />
          <Input
            label={t('general.url')}
            placeholder={t('general.url')}
            className={'input-url'}
            value={newSpecificUrl}
            invalid={isInvalidInput && !isValidUrl(newSpecificUrl)}
            onChange={(e) => {
              setNewSpecificUrl(e.target.value);
            }}
          />
          <Button label={t('config_rag.websites.addWebSite')} onClick={handleAddUrl} />
        </div>
        <div>{t('config_rag.websites.urlList')}</div>
        <MultiSelect
          value={currentWebSite?.urls || specificUrl.urls}
          options={currentWebSite?.urls || specificUrl.urls}
          invalid={specificUrl.urls?.length === 0 && currentWebSite?.urls?.length === 0}
          onChange={(e) =>
            isUpdate
              ? setCurrentWebSite({
                  ...currentWebSite,
                  urls: e.value,
                })
              : setSpecificUrl({ ...specificUrl, urls: e.value })
          }
          placeholder={t('general.url')}
          checkboxIcon='pi pi-times'
          emptyMessage={t('config_rag.websites.emptyMultiSelect')}
          display='chip'
          maxSelectedLabels={3}
          pt={{
            checkbox: {
              box: {
                style: {
                  border: 'none',
                  backgroundColor: '#edf1f5',
                  cursor: 'pointer',
                  pointerEvents: 'initial',
                },
              },
              icon: {
                style: { color: 'red', height: '16px', background: '#edf1f5' },
              },
            },
            item: { style: { cursor: 'default', pointerEvents: 'none' } },
          }}
        />
      </>
    );
  };

  const renderDomainUrl = () => {
    return (
      <div className='form-addurls'>
        <Input
          label={t('general.name')}
          className={'input-name'}
          placeholder={t('general.name')}
          value={currentWebSite?.name || domainUrl.name}
          invalid={isUpdate ? isInvalidInput && currentWebSite?.name === '' : isInvalidInput && domainUrl.name === ''}
          onChange={(e) => {
            isUpdate
              ? setCurrentWebSite({ ...currentWebSite, name: e.target.value })
              : setDomainUrl({ ...domainUrl, name: e.target.value });
          }}
        />
        <Input
          label={t('general.url')}
          placeholder={t('general.url')}
          className={'input-url'}
          value={currentWebSite?.domainToCrawl || domainUrl.domainToCrawl}
          invalid={
            isUpdate
              ? isInvalidInput && (currentWebSite?.domainToCrawl === '' || !isValidUrl(domainUrl.domainToCrawl))
              : isInvalidInput && (domainUrl.domainToCrawl === '' || !isValidUrl(domainUrl.domainToCrawl))
          }
          onChange={(e) => {
            isUpdate
              ? setCurrentWebSite({ ...currentWebSite, domainToCrawl: e.target.value })
              : setDomainUrl({ ...domainUrl, domainToCrawl: e.target.value });
          }}
        />
      </div>
    );
  };

  const renderSitemapUrl = () => {
    return (
      <div className='form-addurls'>
        <Input
          label={t('general.name')}
          className={'input-name'}
          placeholder={t('general.name')}
          value={currentWebSite?.name || sitemapUrl.name}
          invalid={isUpdate ? isInvalidInput && currentWebSite?.name === '' : isInvalidInput && sitemapUrl.name === ''}
          onChange={(e) => {
            isUpdate
              ? setCurrentWebSite({ ...currentWebSite, name: e.target.value })
              : setSitemapUrl({ ...sitemapUrl, name: e.target.value });
          }}
        />
        <Input
          label={t('general.url')}
          placeholder={t('general.url')}
          className={'input-url'}
          invalid={
            (isInvalidInput && sitemapUrl.sitemapUrl === '') || (isInvalidInput && !isValidUrl(sitemapUrl.sitemapUrl))
          }
          value={currentWebSite?.sitemapUrl || sitemapUrl.sitemapUrl}
          onChange={(e) => {
            isUpdate
              ? setCurrentWebSite({ ...currentWebSite, sitemapUrl: e.target.value })
              : setSitemapUrl({ ...sitemapUrl, sitemapUrl: e.target.value });
          }}
        />
      </div>
    );
  };

  const renderForm = () => {
    switch (urlType) {
      case 1:
        return renderDomainUrl();
      case 2:
        return renderSitemapUrl();
      case 3:
        return renderSpecificUrl();
      default:
        return null;
    }
  };
  const checkIfInputIsEmpty = () => {
    switch (urlType) {
      case 1:
        return (
          (domainUrl.name === '' && currentWebSite?.name === '') ||
          (domainUrl.domainToCrawl === '' && currentWebSite?.domainToCrawl === '')
        );
      case 2:
        return (
          (sitemapUrl.name === '' && currentWebSite?.name === '') ||
          (sitemapUrl.sitemapUrl === '' && currentWebSite?.sitemapUrl === '')
        );
      case 3:
        return specificUrl.name === '' && currentWebSite?.name === '';
      default:
        return false;
    }
  };

  const checkIfInputIsValid = () => {
    switch (urlType) {
      case 1:
        return isUpdate ? isValidUrl(currentWebSite?.domainToCrawl) : isValidUrl(domainUrl.domainToCrawl);
      case 2:
        return isUpdate ? isValidUrl(currentWebSite?.sitemapUrl) : isValidUrl(sitemapUrl.sitemapUrl);
      case 3:
        return isUpdate ? currentWebSite?.urls?.length > 0 : specificUrl.urls && specificUrl.urls.length > 0;
      default:
        return false;
    }
  };

  const handleAddOrUpdate = () => {
    if (checkIfInputIsEmpty()) {
      setIsInvalidInput(true);
      toastError(t('config_rag.websites.emptyUrlError'));
      return;
    }

    if (!checkIfInputIsValid()) {
      setIsInvalidInput(true);
      toastError(t('config_rag.websites.invalidUrlError'));
      return;
    }

    const urlsToCheck = isUpdate
      ? currentWebSite?.urls
      : urlType === 3
      ? specificUrl.urls
      : urlType === 1
      ? [domainUrl.domainToCrawl]
      : [sitemapUrl.sitemapUrl];
    const invalidUrls = urlsToCheck?.filter((url) => url && !isValidUrl(url));

    if (invalidUrls && invalidUrls.length > 0) {
      setIsInvalidInput(true);
      toastError(t('config_rag.websites.invalidUrlError'));
      return;
    }

    if (isUpdate) {
      handleUpdateWebsite(data);
      setIsInvalidInput(false);
    } else {
      handleAddWebsite(data);
      setIsInvalidInput(false);
      cleanAllData();
    }
  };

  const onChangeUrlType = (e) => {
    setUrlType(e.value);
    setIsInvalidInput(false);
  };

  return (
    <Dialog visible={visible} onHide={onHideModal} dismissableMask={true} header={renderHeaderModal}>
      <ModalUrls>
        <SelectButton
          value={urlType}
          onChange={onChangeUrlType}
          optionLabel='name'
          options={websiteTypes}
          className={'urls-options'}
          allowEmpty={false}
        />
        {renderForm()}
        <Button
          label={t(isUpdate ? 'general.update' : 'general.create')}
          className={'button-add-website'}
          onClick={handleAddOrUpdate}
        />
      </ModalUrls>
    </Dialog>
  );
};

export default ModalWebsites;
